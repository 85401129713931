

export const actionName = 'CERTIFICATION.save_template_score';

export default (dispatch, template_score, install_id, callback) => {
    dispatch({
        type: actionName,
        status: 'REQUEST'
    });

    fetch('/api/Users/certification', {
        method: 'post',
        body: JSON.stringify({
            template_score,
            install_id
        }),
    }).then(response => {
        return response.json();
    }).then(responseData => {
        dispatch({
            type: actionName,
            status: 'SUCCESS',
            result: responseData
        });
        if(callback) {
            callback(responseData);
        }
    }).catch(error => {
        dispatch({
            type: actionName,
            status: 'FAILED',
            result: error
        });
    });
};