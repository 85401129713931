import Request from 'superagent';
import Promise from 'babel-runtime/core-js/promise';
import _map from '../../node_modules/lodash/map';

/**
 * @method 200 success( $message, $data = [] )
 * @method 400 badRequest( $message )
 * @method 401 unauthorized( $message )
 * @method 403 not implemented
 * @method 404 notFound( $message )
 * @method 422 failedValidation( $message )
 * @method 500 error( $message )
**/

let ajax = function ajax( method = 'GET', url='/', data={}, handleProgress = ()=>{} )
{
	return new Promise( (resolve, reject) => {
		let request = Request( method, url )

		request.set('Accept', 'application/json');

		if( method === 'GET' )
		{
			request.query( data )
		}

		if( method === 'POST' || method === 'PUT' )
		{
			//for a file upload we need to handle
			//attaching the data a little bit different
			if( typeof data.file !== 'undefined' )
			{
				//since we have a file attach the progress event
				request.on('progress', event => {
					handleProgress( event, data.file )
				})

				//loop over data object, attach data and the file object
				_map(data, ( value, key ) => {

					//if we have a File object attach it
					if( key == 'file' )
					{
						request.attach(data.file.name, data.file)
						return
					}

					//all other data attach as fields
					request.field(key, value)
				})
				// request.attach(data.file.name, data.file)
			}
			else
			{
				request.send( data )
			}
		}

		request.end( ( err, res ) => {

			//for 401's we don't want to throw an error...
			//these should only be returned from /api/users/login.json
			if( res.status === 401 )
			{
				return resolve({
					statusCode: res.status,
					body:       res.body
				})
			}

			//sometimes when a login has timed out we will get a 403 returned from cake
			//this is not a great way of dealing with this...and it needs improvement
			//but at least by throwing an error we 100% indicate something went wrong under the hood
			if( res.status === 403 )
			{
				alert('Your login has timed out.  Please refresh your browser to initiate a re-login');

				return resolve({
					statusCode: res.status,
					body:       res.body
				})
			}

			resolve({
				statusCode: res.status,
				body:       res.body
			})
		})
	})
}

export default {
	get: function( url, data = {}, passThroughData )
	{
		return ajax( 'GET', url, data, passThroughData );
	},

	post: function( url, data, passThroughData )
	{
		return ajax( 'POST', url, data, passThroughData );
	},

	put: function (url, data, passThroughData)
	{
		return ajax('PUT', url, data, passThroughData);
	},

	delete: function (url, data = {}, passThroughData) {
		return ajax('DELETE', url, data, passThroughData);
	}
}
