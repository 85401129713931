import Invariant from 'invariant'
import {fromJS} from 'immutable'

export default function(state = {}, action) {
	let sub_service_id
	let line_item_id
	let packageLineItems
	let ModulePackageLines
	let ModuleFactors
	let factor_id
	let factors
	let discount_id
	let discounts
	let parent_id
	let ModuleDiscount
	let companies

	switch (action.type)
	{
		case 'FACTOR_ADDED':
			sub_service_id = action.data.ModuleFactor.module_sub_service_id || false;
			factor_id      = action.data.ModuleFactor.id || false;

			Invariant( factor_id, 'Invalid factor id item returned' )

			//only add this factor if its a child of a sub service
			if( sub_service_id )
			{
				factors = state.getIn([ sub_service_id, 'ModuleFactor' ])

				state = state.setIn([ sub_service_id, 'ModuleFactor' ], factors.push( factor_id ) )
			}

			return state

		case 'FACTOR_DELETED':
			//first lets see if this is a sub factor
			//and if so look up its parent and remove the reference
			sub_service_id = action.factor.get('module_sub_service_id', null)
			if( sub_service_id !== null )
			{
				ModuleFactors = state
					.getIn([ sub_service_id, 'ModuleFactor' ])
					.filter( factor_id => factor_id !== action.factor.get('id') )

				state = state.setIn([ sub_service_id, 'ModuleFactor' ], ModuleFactors)
			}

			return state

		case 'PACKAGE_LINE_ITEM_ADDED':
			sub_service_id = action.data.ModulePackageLine.module_sub_service_id || false;
			line_item_id   = action.data.ModulePackageLine.id || false;

			Invariant( sub_service_id, 'Invalid sub service id returned' )
			Invariant( line_item_id, 'Invalid package line item returned' )

			packageLineItems = state.getIn([ sub_service_id, 'ModulePackageLine' ])

			return state.setIn([ sub_service_id, 'ModulePackageLine' ], packageLineItems.push( line_item_id ) )

		case 'PACKAGE_LINE_ITEM_DELETED':

			sub_service_id = action.lineItem.get('module_sub_service_id');

			ModulePackageLines = state
				.getIn([ sub_service_id, 'ModulePackageLine' ])
				.filter( line_item_id => line_item_id !== action.lineItem.get('id') )

			return state.setIn([ sub_service_id, 'ModulePackageLine' ], ModulePackageLines)

		case 'DISCOUNT_ADDED':
			sub_service_id = action.data.ModuleDiscount.module_sub_service_id || false;
			discount_id    = action.data.ModuleDiscount.id || false;

			//if parent is false we can assume this discount does not belong to a factor option
			if( ! sub_service_id )
			{
				return state;
			}

			Invariant( discount_id, 'Invalid discount id returned' )

			discounts = state.getIn([ sub_service_id, 'ModuleDiscount' ])

			return state.setIn([ sub_service_id, 'ModuleDiscount' ], discounts.push( discount_id ) )

		case 'DISCOUNT_DELETED':
			//first can we find the parent factor?
			parent_id = action.discount.get( 'module_sub_service_id' )

			if( parent_id )
			{
				ModuleDiscount = state
					.getIn([ parent_id, 'ModuleDiscount' ])
					.filter( id => id !== action.discount.get('id') )

				state = state.setIn([ parent_id, 'ModuleDiscount' ], ModuleDiscount)
			}

			return state;

		case 'SUB_SERVICE_RECIEVED_DEPLOYED_TO_COMPAINES':

			//make sure we have a sub service
			let sub_service = state.get(action.sub_service_id)
			Invariant(sub_service, 'could not find sub service in state')

			companies = fromJS(action.companies)

			companies.map( (company) => {
				state = state.setIn([ action.sub_service_id, 'WillDeployToo', company ], true)
			})

			return state.setIn([action.sub_service_id, 'DeployedToo'], companies )

		//default just return state
		default:
			return state;
	}
}
