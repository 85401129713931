

export const actionName = 'CERTIFICATION.load';


let _is_loading = false;
export default (dispatch) => {
    if(_is_loading) {
        //we want to make sure we don't do multiple load requests at a time
        return;
    }
    _is_loading = true;
    dispatch({
        type: actionName,
        status: 'REQUEST',
    });
    fetch('/api/Users/certification')
        .then(response => {
            return response.json();
        }).then(responseData => {
        dispatch({
            type: actionName,
            status: 'SUCCESS',
            result: responseData
        });
        _is_loading = false;
    }).catch(error => {
        dispatch({
            type: actionName,
            status: 'FAILED',
            result: error
        });
        _is_loading = false;
    });
};