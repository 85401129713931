import {actionName as loadActionName} from 'actions/certification/load';
import {actionName as changeCourseActionName} from 'actions/certification/change-course';
import {actionName as saveTemplateScoreActionName} from 'actions/certification/save-template-score';


const initialState = {
    has_loaded: false,
    is_loading: true,
    is_changing_course: false,
    certificates_by_install_id: {},
    actions_by_install_id: {},
    certified_install_ids: [],
    permanent_certificates: [],
};

export default (state = Object.assign({}, initialState), action) => {
    switch(action.type) {
        case changeCourseActionName:
            if(action.status === 'REQUEST') {
                return {...state, is_changing_course: true};
            }
            return {...state, is_changing_course: false};
        case saveTemplateScoreActionName:
            if(action.status === 'SUCCESS') {
                return {
                    ...state,
                    certificates_by_install_id: action.result.certificates_by_install_id,
                    actions_by_install_id: action.result.actions_by_install_id,
                    certified_install_ids: action.result.certified_install_ids,
                    permanent_certificates: action.result.permanent_certificates,
                    active_install_id: action.result.active_install_id,
                    active_number_of_sections: action.result.active_number_of_sections,
                    is_loading: false,
                    has_loaded: true,
                };
            }
            break;
        case loadActionName:

            if(action.status === 'REQUEST') {
                return {...state, is_loading: true, has_loaded: true};
            } else if(action.status === 'SUCCESS') {

                return {
                    ...state,
                    certificates_by_install_id: action.result.certificates_by_install_id,
                    actions_by_install_id: action.result.actions_by_install_id,
                    certified_install_ids: action.result.certified_install_ids,
                    permanent_certificates: action.result.permanent_certificates,
                    active_install_id: action.result.active_install_id,
                    active_number_of_sections: action.result.active_number_of_sections,
                    is_loading: false,
                    has_loaded: true,
                };
            }
            break;
    }
    return state;
};