
export const actionName = 'CERTIFICATION.courses.change';

export default (dispatch, status) => {
    dispatch({
        type: actionName,
        status,
    });

};
