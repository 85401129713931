import ApplicationReducer from 'Store/Models/Application/reducer'
import ModelReducer from 'Store/Models/index'
import SubscriptionsReducer from 'Store/Models/Subscriptions/reducer'
import Immutable from 'immutable'
import CertificationReducer from 'reducers/certification';
import GuidedSetupReducer from 'reducers/guided-setup';

//keeps the array simpler
//edit this to effect the meta info in all models below
const META = {
	loading: false,
	last_loaded: null
}

const initialState = Immutable.fromJS({
	Application: {
		section_name: 'ResponsiBid Admin',
		//can be changed so different styles can be applied to the container
		app_container_class: 'responsibid-app',
		services_filter: '',
	},
	Models: {
		META: {
			errors: {}
		},
		Company:{META},
		User: {META},
		ModuleService : {META},
		ModuleSubService: {META},
		ModuleFactor: {META},
		HouseSizes: {META},
		ModuleFactorOption: {META},
		ModulePackageLine: {META},
		ModuleImageLibrary: {
			loaded: false
		},
		Labels: {META},
		ScoreBoard: {META},
		FollowUpSequence: {META},
		FollowUpStep: {META},
		ApiKeys: {META},
	},
	ScoreBoard: {},
})

export default function RootReducer(state = initialState, action) {

	//Application Reducer
	state = state.set('Application', ApplicationReducer( state.get('Application'), action ))

	state = state.set('Models', ModelReducer( state.get('Models'), action ))

	state = state.set('Subscriptions', SubscriptionsReducer( state.get('Subscriptions'), action ))

	state = state.set('Certification', CertificationReducer(state.get('Certification'), action))

	state = state.set('GuidedSetup', GuidedSetupReducer(state.get('GuidedSetup'), action))
	return state;
}
