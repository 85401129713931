import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './reducer'

let middleware = [thunk];

const store = createStore(
	rootReducer,
	compose(
		applyMiddleware(...middleware),
		//(window.devToolsExtension && process.env.NODE_ENV !== 'production' ) ? window.devToolsExtension() : f => f
		window.devToolsExtension ? window.devToolsExtension() : f => f
	)
)

export default store
