export default function(state = {}, action) {
	switch (action.type) {
		case 'HOUSE_SIZES_LOADING':
			state = state.setIn(['META', 'loading'], true)
			state = state.setIn(['META', 'last_loaded'], null)

			return state

		case 'HOUSE_SIZES_LOADED':
			state = state.setIn(['META', 'loading'], false)
			state = state.setIn(['META', 'last_loaded'], Date.now())

			return state
		//default just return state
		default:
			return state
	}
}
