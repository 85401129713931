// @flow
import React from 'react'
import styled from 'styled-components'
import CircularProgress from 'material-ui/CircularProgress'

const Wrapper = styled.div`
	text-align: center;
    color: grey;
`

export default class Loader extends React.Component {
	props: {
		message: string
	}

	static defaultProps = {
		message: 'Loading...'
	}

	render() {
		if( this.props.pastDelay === false )
		{
			return null
		}

		return (
            <Wrapper>
                <h1 className="loading-title">{this.props.message}</h1>

                <CircularProgress size={80} thickness={5} />
            </Wrapper>
		)
	}
}
