import { CERTIFICATION_SETUP_QUERY } from '../../graphql/guided-setup';

export const actionName = 'GUIDED_SETUP.load';

let _is_loading = false;
export default (dispatch) => {
    if(_is_loading) {
        return;
    }
    _is_loading = true;
    dispatch({
        type: actionName,
        status: 'REQUEST',
    });
	const graphql = process.env.NEXT_APP_GRAPHQL_URI;
    fetch(graphql, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
		credentials: 'include',
        body: JSON.stringify({
			query: CERTIFICATION_SETUP_QUERY
        })
    })
    .then(response => {
        return response.json();
    })
    .then(responseData => {
        dispatch({
            type: actionName,
            status: 'SUCCESS',
            result: responseData
        });
        _is_loading = false;
    }).catch(error => {
        dispatch({
            type: actionName,
            status: 'FAILED',
            result: error
        });
        _is_loading = false;
    });
};
