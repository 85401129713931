
export const CERTIFICATION_SETUP_QUERY = `
	query CertificationSetups {
		certificationSetups {
			id
			title
			description
			url
			order
			certificationTasks {
				id
				title
				description
				url
				order
				isDone
			}
		}
	}
`;
