// @flow
export default function(state: Object = {}, action: Object) {
	switch (action.type) {
		case 'PACKAGE_LINE_ITEM_DELETED':
			//remove the package line from the model repo as well
			return state.delete(action.lineItem.get('id'))

		case 'PACKAGE_LINE_ITEM_SORT_ORDER_CHANGED':
			//re-save the options with a new sort order
			action.package_line_items.map((id, order) => {
				state = state.setIn([id, 'order'], order)
			})

			return state
		//default just return state
		default:
			return state
	}
}
