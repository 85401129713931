import { API_KEY_LOADING, API_KEY_LOADED } from "../../../constants/apiKey";

export default function(state = {}, action) {
	switch (action.type) {
		case API_KEY_LOADING:
			return state.setIn(['META', 'loading'], true)
				.setIn(['META', 'last_loaded'], null);

		case API_KEY_LOADED:
			return state.setIn(['META', 'loading'], false)
				.setIn(['META', 'last_loaded'], Date.now());

		default:
			return state;
	}
}
