import invariant from 'invariant'
/**
 * Given an immutable.js META object from one of our model stores determine if we need to update or not
 *
 * @param META
 * @param timeBetweenUpdates in minutes
 */

export default function( META, timeBetweenUpdates = 5 )
{
	invariant(META, 'Invalid Meta object passed into shouldWeUpdate')

	//if we are currently loading then there is no need to load again
	if( META.get('loading', false) === true )
	{
		return false
	}

	//if the data was last loaded five minutes ago then don't reload
	const lastLoaded = META.get('last_loaded', null)
	const FIVE_MINUTES = 60 * timeBetweenUpdates * 1000; /* ms */

	return Date.now() - lastLoaded >= FIVE_MINUTES;
}
