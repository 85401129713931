import API from 'Store/api'
import { normalize } from 'normalizr'
import _get from 'lodash/get'
import shouldWeUpdate from 'Shared/Helpers/shouldUpdateOLD'
import UserSchema from 'Store/Models/Users/schema'
import CompanySchema from 'Store/Models/Company/schema'
import flagsmith from 'flagsmith';
import { getAuthCompany } from './selectors'
import store from '../../index'

export const load = function(force = false) {
	return function(dispatch, getState) {
		//when we pass in force do not do this check
		if (!force && !shouldWeUpdate(getState().getIn(['Models', 'User', 'META']))) {
			return
		}

		//set marker that we are loading
		dispatch({
			type: 'MODEL_LOADING',
			model: 'User'
		})

		//now make the call to load companies
		return API.get('/api/Users/index.json')
			.then(json => {
				const ResponseSchema = {
					User: UserSchema.User
				}

				const data = normalize(json.body.data, [ResponseSchema])

				dispatch({
					type: 'MODELS_ADD',
					receivedAt: Date.now(),
					models: data.entities
				})

				dispatch({
					type: 'MODEL_LOADED',
					model: 'User'
				})

				return json
			})
			.then(() => {
				//This is done to make sure the current auth user with their specially set keys does not get over written
				//by the above call to load all users
				dispatch(loadAuthUser(true))
			})
	}
}

export const add = function(user) {
	return function(dispatch) {
		//set marker that we are loading
		dispatch({
			type: 'MODEL_LOADING',
			model: 'User'
		})

		//now make the call to load companies
		return API.post('/api/Users/add.json', user)
			.then(json => {
				//handle our unauthorized errors
				if (json.body.code !== 200 && json.body.message) {
					dispatch({
						type: 'USER_ADD_STATUS',
						status: 'failed',
						message: json.body.message
					})

					return json
				}

				const data = normalize(json.body.data.User, UserSchema.User)

				dispatch({
					type: 'MODELS_ADD',
					receivedAt: Date.now(),
					models: data.entities
				})

				dispatch({
					type: 'USER_ADD_STATUS',
					status: 'success',
					message: 'New user has been added'
				})

				return json
			})
			.then(() => {
				dispatch({
					type: 'MODEL_LOADED',
					model: 'User'
				})
			})
	}
}

export const remove = function(id) {
	return function(dispatch) {
		//delete user from our local store
		dispatch({
			type: 'USER_DELETE',
			id
		})

		//now persist this change to the server
		return API.post(`/api/Users/delete/${id}.json`)
	}
}

export const changePassword = function(data) {
	return function(dispatch) {
		//set marker that we are loading
		dispatch({
			type: 'MODEL_LOADING',
			model: 'User'
		})

		//now make the call to load companies
		return API.post('/api/Users/changePassword.json', data)
			.then(json => {
				//handle our unauthorized errors
				if (json.body.code === 200 && json.body.message) {
					dispatch({
						type: 'USER_CHANGE_PASSWORD_STATUS',
						status: 'success',
						message: 'Password has been updated'
					})

					return json
				}

				if (json.body.message) {
					dispatch({
						type: 'USER_CHANGE_PASSWORD_STATUS',
						status: 'failed',
						message: json.body.message
					})

					return json
				}

				dispatch({
					type: 'USER_CHANGE_PASSWORD_STATUS',
					status: 'failed',
					message: 'Password update failed for an unknown reason'
				})

				return json
			})
			.then(() => {
				dispatch({
					type: 'MODEL_LOADED',
					model: 'User'
				})
			})
	}
}

export const loadAuthUser = function(force = false, callback) {
	return function(dispatch, getState) {
		//when we pass in force do not do this check
		if (!force && !shouldWeUpdate(getState().getIn(['Models', 'User', 'META']))) {
			return
		}

		//set marker that we are loading
		dispatch({
			type: 'MODEL_LOADING',
			model: 'User'
		})

		//set marker that we are loading
		dispatch({
			type: 'MODEL_LOADING',
			model: 'Company'
		})

		//now make the call to load services
		return API.get('/api/Users/view/me.json')
			.then(json => {
				processAuthUserData(json, dispatch)

			})
			.then(json => {
				dispatch({
					type: 'MODEL_LOADED',
					model: 'User'
				})

				dispatch({
					type: 'MODEL_LOADED',
					model: 'Company'
				});

				if(callback) {
					callback(json);
				}
				return json
			})
	}
}

export const login = function(credentials) {
	return function(dispatch) {
		dispatch({
			type: 'LOGGING_IN',
			state: true
		})

		//set timeOut is here so the spinner in the login button shows up
		//not sure this is a good idea to make a login feel slower then they really are
		//but I like the spinner effect....so this stays for now
		setTimeout(() => {
			API.post('/api/users/login', { User: credentials })
				.then(json => {
					if (json.statusCode === 401) {
						dispatch({
							type: 'LOG_IN_FAILED',
							message: _get(json, 'body.message.reason', 'Unknown response from server')
						})

						return
					}
					//Lets redict because the logic below DOES NOT WORK!
					window.location.href = '/';
					return;

					processAuthUserData(json, dispatch)

					dispatch({
						type: 'LOGGING_IN',
						state: false
					})
				})
				.catch(e => {
					//when we get a 500 error its best to refresh the page
					//this will often make the error go away...in my testing
					//500 errors could happen when the user **is** logged in
					//but the react app did not refresh to catch up.  The user
					//clicks login again then cakePHP redirects to / which errors
					//because it attempts to serve a non existent json version of the page
					if (e.status === 500) {
						window.location.reload()
					}
				})
		}, 1)
	}
}

export const initializeFlagsmith = () => async (dispatch, getState) => {
	const state = getState();
	let company = getAuthCompany(state);

	if (!company.get('id')) {
		await new Promise(resolve => {
			const unsubscribe = store.subscribe(() => {
				const newState = getState();
				const newCompany = getAuthCompany(newState);
				if (newCompany.get('id')) {
					unsubscribe();
					resolve();
				}
			});
		});
		company = getAuthCompany(getState());
	}

	const identity = `${company.get('name')}-${company.get('id')}`;

	await flagsmith.init({
		environmentID: process.env.FLAGSMITH_SDK_KEY,
		identity: identity
	});

	const flags = flagsmith.getAllFlags();
	dispatch({
		type: 'SET_FLAGSMITH_FLAGS',
		flags
	});
};

const processAuthUserData = (json, dispatch) => {
	//are we logged in?
	if (_get(json, 'body.data.User.id')) {
		//set flag so we know who the auth user is

		dispatch({
			type: 'SET_AUTH_USER_ID',
			receivedAt: Date.now(),
			id: json.body.data.User.id
		})

		const user = normalize(json.body.data.User, UserSchema.User)
		const company = normalize(json.body.data.Company, CompanySchema.Company)

		dispatch({
			type: 'MODELS_ADD',
			receivedAt: Date.now(),
			models: Object.assign({}, user.entities, company.entities)
		})

		//mark the user as logged_in only after all other auth data has been saved
		dispatch({
			type: 'SET_AUTH_STATE',
			receivedAt: Date.now(),
			status: true
		})

		return json
	}

	//if we get this far we appear to be not logged in
	dispatch({
		type: 'SET_AUTH_STATE',
		receivedAt: Date.now(),
		status: false
	})

	return json
}
