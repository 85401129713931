import { actionName as loadActionName } from 'actions/guided-setup/load';

const initialState = {
	has_loaded: false,
    is_loading: true,
    certification_setups: []
};

export default (state = Object.assign({}, initialState), action) => {
    switch(action.type) {
        case loadActionName:
            if(action.status === 'REQUEST') {
                return {...state, is_loading: true};
            } else if(action.status === 'SUCCESS') {
                return {
					...state,
					certification_setups: action.result.data.certificationSetups.map((setup) => ({
						...setup,
						isDone: setup.certificationTasks.every((task) => task.isDone)
					})),
					is_loading: false,
					has_loaded: true
				};
            }
            break;
    }
	return state;
}
