const sizes = {
	sm: '640px',
	md: '768px',
	lg: '1024px',
	xl: '1280px',
	xxl: '1536px'
}

const theme = {
	blue: '#0073FF',
	altBlue: '#174975',
	lightBlue: '#009ddc',
	darkBlue: '#041844',
	lightGrey: '#A0A0A0',
	green: '#00C441',
	red: '#ff6363',
	white: '#FFFFFF',

	desktop: '@media ( min-width: 992px )',
	tablet: '@media ( min-width: 768px )',
	phone: '@media ( max-width: 768px )',

	screenSizes: {
		sm: `@media (min-width: ${sizes.sm})`,
		md: `@media (min-width: ${sizes.md})`,
		lg: `@media (min-width: ${sizes.lg})`,
		xl: `@media (min-width: ${sizes.xl})`,
		xxl: `@media (min-width: ${sizes.xxl})`
	}
}

export default theme
