export default function(state = {}, action) {
	//declare variables up here to avoid already declared errors below

	switch (action.type) {
		case 'COMPANY_SET_TEST':
			console.log(action)
			if (action.testName === 'free_trial') {
				state = state.setIn([action.company_id, 'has_free_trial'], true)
				state = state.setIn([action.company_id, 'deactivate_date'], moment().add(14, 'days').format('YYYY-MM-DD'))
				state = state.setIn([action.company_id, 'subscription_managed_by'], 'responsibid')
			}

			if (action.testName === 'account_expired') {
				state = state.setIn([action.company_id, 'has_free_trial'], false)
				state = state.setIn([action.company_id, 'active'], false)
				state = state.setIn([action.company_id, 'subscription_managed_by'], 'responsibid')
			}

			if (action.testName === 'account_just_pastdue') {
				state = state.setIn([action.company_id, 'has_free_trial'], false)
				state = state.setIn([action.company_id, 'deactivate_date'], moment().add(14, 'days').format('YYYY-MM-DD'))
				state = state.setIn([action.company_id, 'paid_until_date'], moment().format('YYYY-MM-DD'))
				state = state.setIn([action.company_id, 'subscription_managed_by'], 'responsibid')
			}

			if (action.testName === 'account_5_days_pastdue') {
				state = state.setIn([action.company_id, 'has_free_trial'], false)
				state = state.setIn([action.company_id, 'deactivate_date'], moment().add(9, 'days').format('YYYY-MM-DD'))
				state = state.setIn([action.company_id, 'paid_until_date'], moment().subtract(5, 'days').format('YYYY-MM-DD'))
				state = state.setIn([action.company_id, 'subscription_managed_by'], 'responsibid')
			}

			return state
		//default just return state
		default:
			return state
	}
}
