export default function(state = {}, action) {
	//declare variables up here to avoid already declared errors below

	switch (action.type) {
		case 'LABELS_LOADING':
			return state.setIn(['META', 'loading'], true).setIn(['META', 'last_loaded'], null)

		case 'LABELS_LOADED':
			return state.setIn(['META', 'loading'], false).setIn(['META', 'last_loaded'], Date.now())
		//default just return state
		default:
			return state
	}
}
