import ReactDOM from 'react-dom'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import injectTapEventPlugin from 'react-tap-event-plugin'
import { Provider } from 'react-redux'
import App from './app'
import store from './Store/index'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import 'styles/theme.css'
import theme from 'styles/theme'

if(window.location.hostname && window.location.hostname.indexOf('dev') === -1 && window.location.hostname.indexOf('localhost') === -1 && window.location.protocol !== 'https:') {
	window.location.protocol = "https:";
}

//Adding in Array.find() since old IE does not have
if (!Array.prototype.find) {
	Array.prototype.find = function(predicate) {
		if (this == null) {
			throw new TypeError('Array.prototype.find called on null or undefined');
		}
		if (typeof predicate !== 'function') {
			throw new TypeError('predicate must be a function');
		}
		var list = Object(this);
		var length = list.length >>> 0;
		var thisArg = arguments[1];
		var value;

		for (var i = 0; i < length; i++) {
			value = list[i];
			if (predicate.call(thisArg, value, i, list)) {
				return value;
			}
		}
		return undefined;
	};
}
// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
injectTapEventPlugin()

const muiTheme = getMuiTheme({
	textField: {
		focusColor: theme.blue
	}
})

var rootEl = document.getElementById('root')

// Create a reusable render method that we can call more than once
let render = () => {
	// Dynamically import our main App component, and render it
	const App = require('./app').default;

	ReactDOM.render(
		<MuiThemeProvider muiTheme={muiTheme}>
			<Router>
				<Provider store={store}>
					<App />
				</Provider>
			</Router>
		</MuiThemeProvider>,
		rootEl
	)
}

if (module.hot) {
	// Support hot reloading of components
	// and display an overlay for runtime errors
	const renderApp = render
	const renderError = error => {
		const RedBox = require('redbox-react').default
		ReactDOM.render(<RedBox error={error} />, rootEl)
	}

	// In development, we wrap the rendering function to catch errors,
	// and if something breaks, log the error and render it to the screen
	render = () => {
		try {
			renderApp()
		} catch (error) {
			console.error(error)
			renderError(error)
		}
	}

	// Whenever the App component file or one of its dependencies
	// is changed, re-import the updated component and re-render it
	module.hot.accept('./app', () => {
		setTimeout(render)
	})
}

render()
