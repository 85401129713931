
export default function(state = {}, action) {
	switch (action.type)
	{
		case 'SET_SECTION_NAME':
			return state.set('section_name', action.name)

		case 'SET_APP_CONTAINER_CLASS':
			return state.set('app_container_class', action.name)

		case 'APPLICATION_SET_KEY_VALUE':
			return state.set(action.key, action.value)

		case 'SERVICES_FILTER':
			return state.set('services_filter', action.filter)

		case 'APPLICATION_TOGGLE_SIDEBAR':
			const showSideBar = state.get('showSideBar', false)
			return state.set('showSideBar', !showSideBar)

		case 'APPLICATION_HIDE_SIDEBAR':
			return state.set('showSideBar', false)

		case 'SUB_APP_VIABILITY':
			return state.set('showSubApp', action.visible)

		case 'LOGIN_REDIRECT':
			return state.set('loginRedirect', action.href)

		//simply return the state if none of the action types apply to this reducer
		default:
			return state;
	}
}
