import {fromJS} from 'immutable'

export default function(state = {}, action) {
	switch (action.type)
	{
		case 'SET_AUTH_USER_ID':
			return state.setIn(['META', 'auth_user_id'], action.id)

		case 'SET_AUTH_STATE':
			if( action.status === false )
			{
				state = state.setIn(['META', 'auth_user_id'], null)
			}
			state = state.setIn(['META', 'logged_in'], action.status)
			return state

		case 'LOGGING_IN':
			state = state.deleteIn(['META', 'log_in_fail_message'])
			state = state.setIn(['META', 'logging_in'], action.state)

			return state

		case 'LOG_IN_FAILED':
			state = state.setIn(['META', 'logged_in'], false)
			state = state.setIn(['META', 'logging_in'], false)
			state = state.setIn(['META', 'log_in_fail_message'], action.message)

			return state

		case 'USER_ADD_STATUS':
			state = state.setIn(['META', 'add_user_status'], action.status)
			state = state.setIn(['META', 'add_user_message'], action.message)

			return state

		case 'USER_CHANGE_PASSWORD_STATUS':
			state = state.setIn(['META', 'password_status'], action.status)
			state = state.setIn(['META', 'password_message'], action.message)

			return state

		case 'USER_DELETE':
			return state.delete(action.id)

		case 'SET_FLAGSMITH_FLAGS':
			return state.set('flags', fromJS(action.flags));

		//default just return state
		default:
			return state
	}
}

