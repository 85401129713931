//import Invariant from 'invariant'
import { fromJS, Map } from 'immutable'
import ModulePackageLineReducer from 'Store/Models/ModulePackageLine/reducer'
import ModuleServiceReducer from 'Store/Models/ModuleService/reducer'
import ModuleSubServiceReducer from 'Store/Models/ModuleSubService/reducer'
import ModuleFactorReducer from 'Store/Models/ModuleFactor/reducer'
import ModuleFactorOptionReducer from 'Store/Models/ModuleFactorOption/reducer'
import ModuleDiscountReducer from 'Store/Models/ModuleDiscount/reducer'
import ModuleImageLibraryReducer from 'Store/Models/ModuleImageLibrary/reducer'
import CompanyReducer from 'Store/Models/Company/reducer'
import HouseSizesReducer from 'Store/Models/HouseSizes/reducer'
import LabelsReducer from 'Store/Models/Labels/reducer'
import UsersReducer from 'Store/Models/Users/reducer'
import FollowUpSequenceReducer from 'Store/Models/FollowUpSequence/reducer'
import ApiKeyReducer from 'Store/Models/ApiKey/reducer'

const ModelReducer = function(state = {}, action) {
	//declare variables up here to avoid already declared errors below
	// let sub_service_id;
	// let ModulePackageLines;

	switch (action.type) {
		case 'MODELS_ADD':
			return state.mergeDeep(fromJS(action.models))
		//these methods keep track of our app state....are we dirty, saving, saved
		case 'MARK_APP_STATE_AS_SAVING':
			return state.setIn(['META', 'synced_with_server'], 'saving')

		case 'MODEL_HAS_BEEN_SAVED_AT_PATH':
			return state.setIn(['META', 'synced_with_server'], 'saved')

		case 'MARK_APP_STATE_AS_DIRTY':
			return state.setIn(['META', 'synced_with_server'], 'dirty')

		case 'MARK_APP_STATE_AS_IN_ERROR':
			return state.setIn(['META', 'synced_with_server'], 'errors')

		case 'ERROR_MARK_AT_PATH':
			let path = action.data.path.slice()

			path.unshift('META', 'errors', action.data.model, action.data.id)

			state = state.setIn(path, action.error_message)

			//finally return state synced_with_server set to an error state
			return state.setIn(['META', 'synced_with_server'], 'errors')

		case 'MODEL_LOADING':
			state = state.setIn([action.model, 'META', 'loading'], true)
			state = state.setIn([action.model, 'META', 'last_loaded'], null)

			return state

		case 'MODEL_LOADED':
			state = state.setIn([action.model, 'META', 'loading'], false)
			state = state.setIn([action.model, 'META', 'last_loaded'], Date.now())

			return state

		case 'MODEL_SAVE_AT_PATH':
			//fun times....if any key in our path is set to NULL
			//then setIn will fail.  So lets traverse the path
			//and if any value is set to null lets delete it

			//step 1 get a copy of the path
			let testPath = action.path.slice()

			do {
				let value = state.getIn(testPath)

				//if the returned value is null...then we want to unset it
				if (value === null) {
					state = state.deleteIn(testPath)
				}

				//remove one key from the array for the next test
				testPath.pop()
			} while (testPath.length >= 1)

			//clear any errors that may have been set at this path
			let errorPath = action.path.slice()
			errorPath.unshift('META', 'errors')
			do {
				state = state.deleteIn(errorPath)
				errorPath.pop()
			} while (state.getIn(errorPath, new Map()).size === 0 && errorPath.length > 2)

			//set the value to the path
			state = state.setIn(action.path, action.value)

			return state
		//if no general model operations were ran for this action lets load the
		//individual model reducers
		default:
			//META  -  placeholder we don't actually have a meta reducer
			//but if we don't include this then the state will no longer have a META
			state = state.set('META', state.get('META'))
			state = state.set('Company', CompanyReducer(state.get('Company'), action))
			state = state.set('User', UsersReducer(state.get('User'), action))
			state = state.set('HouseSizes', HouseSizesReducer(state.get('HouseSizes'), action))
			state = state.set('ModuleService', ModuleServiceReducer(state.get('ModuleService'), action))
			state = state.set('ModuleSubService', ModuleSubServiceReducer(state.get('ModuleSubService'), action))
			state = state.set('ModuleFactor', ModuleFactorReducer(state.get('ModuleFactor'), action))
			state = state.set('ModuleFactorOption', ModuleFactorOptionReducer(state.get('ModuleFactorOption'), action))
			state = state.set('ModulePackageLine', ModulePackageLineReducer(state.get('ModulePackageLine'), action))
			state = state.set('ModuleDiscount', ModuleDiscountReducer(state.get('ModuleDiscount'), action))
			state = state.set('ModuleImageLibrary', ModuleImageLibraryReducer(state.get('ModuleImageLibrary'), action))
			state = state.set('Labels', LabelsReducer(state.get('Labels'), action))
			state = state.set('FollowUpSequence', FollowUpSequenceReducer(state.get('FollowUpSequence'), action))
			state = state.set('ApiKeys', ApiKeyReducer(state.get('ApiKeys'), action));

			return state
	}
}

export default ModelReducer
