import {fromJS, Map} from 'immutable'

const defaultState = fromJS({
	invoices: [],
	paymentDetails: {
		cards: [],
		previousDefault: 'add_new',
		default: 'add_new',
		loading: true,
		last_loaded: undefined,
	},
	addPaymentSource: {
		loading: false,
		error: ''
	},
	plans: {
		loaded: false,
		billing_cycles: [],
		activeSubscription: {},
		change: {},
	},
	chargeNow: {
		loading:false,
		show: false,
		charge_amount: false,
		confirm: false
	}
})

export default function(state = defaultState, action) {

	switch (action.type)
	{
		case 'SUBSCRIPTIONS_ACCOUNT_LOADED' :
			state = state.setIn(['invoices'], fromJS(action.invoices))
			state = state.setIn(['account'], fromJS(action.account))
			return state

		case 'SUBSCRIPTIONS_UPDATE_PAYMENT_SOURCES':
			state = state.setIn(['paymentDetails', 'previousDefault'], state.getIn(['paymentDetails', 'default']))
			state = state.setIn(['paymentDetails', 'default'], action.defaultPaymentSource)
			state = state.setIn(['paymentDetails', 'cards'], fromJS(action.paymentSources))
			state = state.setIn(['paymentDetails', 'loaded'], action.loaded)
			state = state.setIn(['paymentDetails', 'last_loaded'], Date.now())

			return state

		case 'SUBSCRIPTIONS_ADD_PAYMENT_SOURCE':
			state = state.setIn(['addPaymentSource', 'loading'], action.loading)
			state = state.setIn(['addPaymentSource', 'error'], action.error)
			return state

		case 'SUBSCRIPTIONS_PAYMENT_SOURCE_MAKE_DEFAULT' :
			state = state.setIn(['paymentDetails', 'previousDefault'], state.getIn(['paymentDetails', 'default']))
			return state.setIn(['paymentDetails', 'default'], action.payment_source_id)

		case 'SUBSCRIPTIONS_PLANS' :
			state = state.setIn(['plans', 'loaded'], action.loaded)
			state = state.setIn(['plans', 'billing_cycles'], fromJS(action.billing_cycles))
			state = state.setIn(['plans', 'activeSubscription'], fromJS(action.activeSubscription))
			return state

		case 'SUBSCRIPTIONS_PLANS_CHANGE' :

			//if the change is confirmed assume save was good and reset change object
			if( action.confirmPlanChange )
			{
				return state.setIn(['plans', 'change'], new Map({
					changeConfirmed: true,
					needToConfirm: true //keeps the modal open so we can show the success icon
				}))
			}

			//not a confirm save so set change details here
			state = state.setIn(['plans', 'change', 'loading'], action.loading)
			state = state.setIn(['plans', 'change', 'needToConfirm'], true)
			state = state.setIn(['plans', 'change', 'total_due_today'], action.total_due_today)
			state = state.setIn(['plans', 'change', 'prorate_amount'], action.prorate_amount)
			state = state.setIn(['plans', 'change', 'remaining_cash_credit'], action.remaining_cash_credit)
			state = state.setIn(['plans', 'change', 'subscription_package_cycle_group_id'], action.subscription_package_cycle_group_id)

			return state

		case 'SUBSCRIPTIONS_PLANS_CHANGE_CANCEL' :
			return state.setIn(['plans', 'change'], new Map() )

		case 'SUBSCRIPTIONS_CHARGE_NOW' :
			state = state.setIn(['chargeNow', 'loading'],       action.loading)
			state = state.setIn(['chargeNow', 'show'],          action.show)
			state = state.setIn(['chargeNow', 'charge_amount'], action.charge_amount)
			state = state.setIn(['chargeNow', 'confirm'],       action.confirm)
			return state

		//default just return state
		default:
			return state
	}
}
