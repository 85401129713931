import Invariant from 'invariant'
import { List } from 'immutable'

const ModuleFactorReducer = function(state = {}, action) {
	let factor_id
	let parent_id
	let SubFactors
	let factors
	let option_id
	let options
	let ModuleFactorOption
	let discount_id
	let discounts
	let ModuleDiscount

	switch (action.type) {
		case 'FACTOR_ADDED':
			parent_id = action.data.ModuleFactor.module_factor_id || false
			factor_id = action.data.ModuleFactor.id || false

			Invariant(factor_id, 'Invalid factor id item returned')

			//only add this factor if its a child of a sub service
			if (parent_id) {
				factors = state.getIn([parent_id, 'SubFactors'])

				state = state.setIn([parent_id, 'SubFactors'], factors.push(factor_id))
			}

			return state

		case 'FACTOR_DELETED':
			//first lets see if this is a sub factor
			//and if so look up its parent and remove the reference
			parent_id = action.factor.get('module_factor_id', null)
			if (parent_id) {
				SubFactors = state.getIn([parent_id, 'SubFactors']).filter(factor_id => factor_id !== action.factor.get('id'))

				state = state.setIn([parent_id, 'SubFactors'], SubFactors)
			}

			//last remove factor from state
			return state.delete(action.factor.get('id'))

		case 'FACTOR_OPTION_ADDED':
			parent_id = action.data.ModuleFactorOption.module_factor_id || false
			option_id = action.data.ModuleFactorOption.id || false

			Invariant(option_id, 'Invalid factor option id returned')
			Invariant(parent_id, 'Invalid factor option parent id returned')

			options = state.getIn([parent_id, 'ModuleFactorOption'], new List())

			return state.setIn([parent_id, 'ModuleFactorOption'], options.push(option_id))

		case 'FACTOR_OPTION_DELETED':
			//first can we find the parent factor?
			parent_id = action.option.get('module_factor_id')

			if (parent_id) {
				ModuleFactorOption = state
					.getIn([parent_id, 'ModuleFactorOption'])
					.filter(option_id => option_id !== action.option.get('id'))

				state = state.setIn([parent_id, 'ModuleFactorOption'], ModuleFactorOption)
			}

			return state

		case 'FACTORS_SORT_ORDER_CHANGED':
			//re-save the options with a new sort order
			action.factor_ids.map((factor_id, order) => {
				state = state.setIn([factor_id, 'order'], order)
			})

			return state

		case 'DISCOUNT_ADDED':
			factor_id = action.data.ModuleDiscount.module_factor_id || false
			discount_id = action.data.ModuleDiscount.id || false

			//if parent is false we can assume this discount does not belong to a factor option
			if (!factor_id) {
				return state
			}

			Invariant(discount_id, 'Invalid discount id returned')

			discounts = state.getIn([factor_id, 'ModuleDiscount'], new List())

			return state.setIn([factor_id, 'ModuleDiscount'], discounts.push(discount_id))

		case 'DISCOUNT_DELETED':
			//first can we find the parent factor?
			factor_id = action.discount.get('module_factor_id')

			if (factor_id) {
				ModuleDiscount = state.getIn([factor_id, 'ModuleDiscount']).filter(id => id !== action.discount.get('id'))

				state = state.setIn([factor_id, 'ModuleDiscount'], ModuleDiscount)
			}

			return state
		//default just return state
		default:
			return state
	}
}

export default ModuleFactorReducer
