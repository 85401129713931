import Invariant from 'invariant'
import { Map } from 'immutable'

export default function(state = {}, action) {
	let parent_id
	let discount_id
	let discounts
	let ModuleDiscount

	switch (action.type) {
		case 'FACTOR_OPTION_DELETED':
			//remove the package line from the model repo as well
			return state.delete(action.option.get('id'))

		case 'FACTOR_OPTION_SORT_ORDER_CHANGED':
			//re-save the options with a new sort order
			action.options.map((option_id, order) => {
				state = state.setIn([option_id, 'order'], order)
			})

			return state

		case 'DISCOUNT_ADDED':
			parent_id = action.data.ModuleDiscount.module_factor_option_id || false
			discount_id = action.data.ModuleDiscount.id || false

			//if parent is false we can assume this discount does not belong to a factor option
			if (!parent_id) {
				return state
			}

			Invariant(discount_id, 'Invalid discount id returned')

			discounts = state.getIn([parent_id, 'ModuleDiscount'])

			return state.setIn([parent_id, 'ModuleDiscount'], discounts.push(discount_id))

		case 'DISCOUNT_DELETED':
			//first can we find the parent factor?
			parent_id = action.discount.get('module_factor_option_id')

			if (parent_id) {
				ModuleDiscount = state.getIn([parent_id, 'ModuleDiscount']).filter(id => id !== action.discount.get('id'))

				state = state.setIn([parent_id, 'ModuleDiscount'], ModuleDiscount)
			}

			return state
		//default just return state
		default:
			return state
	}
}
