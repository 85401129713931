const ModuleDiscountReducer = function(state = {}, action) {
	switch (action.type) {
		case 'DISCOUNT_DELETED':
			//remove discount
			return state.delete(action.discount.get('id'))
		//default just return state
		default:
			return state
	}
}

export default ModuleDiscountReducer
